<template>
  <b-modal
    id="modal-create-free-line"
    ref="modal-create-free-line"
    centered="centered"
    :title="
      (freeLine.id ? 'Modifier' : 'Ajouter') +
      (freeLine.typeLine == '3'
        ? ' un titre'
        : freeLine.typeLine == '4'
        ? ' un sous-titre'
        : ' une ligne libre')
    "
    @ok="create"
  >
    <div v-if="isCreatingPlanningLine">
      <div class="text-center flex-center">
        <div class="loading-bg-inner">
          <div class="loader">
            <div class="outer"></div>
            <div class="middle"></div>
            <div class="inner"></div>
          </div>
        </div>
        <div class="mt-5">
          <br /><br /><br />
          Chargement...
        </div>
      </div>
    </div>
    <validation-observer v-else ref="formCreateFreeLine">
      <b-row>
        <b-col cols="12" class="d-flex">
          <b-form-group label="Libellé *" label-for="name" style="flex: 1">
            <validation-provider
              #default="{ errors }"
              name="name"
              rules="required"
            >
              <b-form-input
                id="name"
                v-model="freeLine.name"
                :state="errors.length > 0 ? false : null"
                class="not-autocomplete"
                autocomplete="nope"
                aria-autocomplete="nope"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="12" class="d-flex">
          <b-form-group
            label="Sous libellé"
            label-for="subName"
            style="flex: 1"
          >
            <validation-provider #default="{ errors }" name="subName">
              <b-form-input
                id="subName"
                v-model="freeLine.subName"
                :state="errors.length > 0 ? false : null"
                class="not-autocomplete"
                autocomplete="nope"
                aria-autocomplete="nope"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row> </validation-observer
    ><template #modal-footer="{ ok, cancel }">
      <div class="w-100">
        <b-button class="float-right" variant="primary" @click="ok()">{{
          freeLine.id ? "Modifier" : "Ajouter"
        }}</b-button>
        <b-button
          class="float-right mr-1"
          variant="outline-primary"
          @click="cancel()"
          >Annuler</b-button
        >
      </div>
    </template>
  </b-modal>
</template>

<script>
import {
  ValidationProvider,
  ValidationObserver,
  configure,
  localize,
} from "vee-validate";
import { required, url, email } from "@validations";
import { mapGetters, mapActions } from "vuex";
import Ripple from "vue-ripple-directive";
configure({
  // Generates an English message locale generator
  generateMessage: localize("fr", {
    messages: {
      url: "Ce champ doit être une URL",
      required: "Ce champ est requis",
      email: "Ce champ doit être un email",
    },
  }),
});
localize("fr");

export default {
  data() {
    return {
      url,
      email,
      required,
      returnFreeLine: false,
      freeLine: {
        id: 0,
        name: null,
        subName: null,
        typeLine: 0,
      },
    };
  },
  methods: {
    popupCreateFreeLineEvent(id, type, returnFreeLine = false){
      this.returnFreeLine = returnFreeLine
      this.freeLine.typeLine=type
      if(id>0){
        this.getPlanningLineById({
          planningLineId: id,
        }).then(res => {
          this.freeLine = res
        })
      }else{
        this.initializeForm(type, returnFreeLine)
      }
    },
    initializeForm(type) {
      this.freeLine = {
        id: 0,
        name: null,
        subName: null,
        typeLine: type,
      };
    },
    create(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.$refs.formCreateFreeLine.validate().then((success1) => {
        if (success1) {
          if(this.freeLine.id){
            this.updatePlanningLine({
              planningLine: this.freeLine,
            })
          }else{
            if(this.returnFreeLine){
                this.$emit('returnLine', this.freeLine)
            }else{
              this.createPlanningLine({
                planningLine: this.freeLine,
              }).then(res => {
                this.freeLine.id=res.id
              })
            }
          }


          this.$nextTick(() => {
            this.$refs["modal-create-free-line"].toggle("#toggle-btn");
          });
        }
      });
    },
    ...mapActions(["createPlanningLine", "updatePlanningLine","getPlanningLineById"]),
  },
  computed: {
    ...mapGetters(["isCreatingPlanningLine"]),
  },

  components: {
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
};
</script>
